header {
    margin: 0px;
    padding: 0px;
}

.divHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 8%;
    margin-right: 8%;
}

.menu {
    color: #032d5a;
    font-weight: bold;
    font-size: 2em;
    font-weight: 600;
    height: 25px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.menu_item {
    margin: 0px 0px 0px 0px;
    padding: 0px 10px 0px 10px;
    text-decoration: none;
    /*border-right: 1px solid #80808080;*/
}

.menu_item:hover {
    /*box-shadow: 0px 0px 10px #032d5a;
    transform: translateY(2px);*/
    border-radius: 6px;
    background-color: #E1E1E6;
    padding: 0.1em;
    text-decoration: none;
}

.logo {
    width: 380px;
    height: 220px;
    /* Adicionado para permitir expansão */
    flex-grow: 1;
}

.markerIcon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.callIcon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.address {
    background-color: #0000004d;
    border-radius: 10px;
    padding: 10px 15px 10px 0px;
    display: flex;
    color: #ffffff;
    direction: rtl;
    flex-direction: column;
    width: 550px;
    float: right;
    align-self: flex-end;
    font-weight: bold;
    text-shadow: 1px 1px 1px #000000;
    margin: 25px 5px 0px 0px;
}

.divAddress {
    display: flex;
}

.divTell {
    display: flex;
    margin-Top: 10px;
}

.divFelp {
    background-color: #ffffff;
}

.divFelp h3 {
    color: #002E38;
}

footer {
    color: #ffffff;
    background-color: #ffffff;
    height: 120px;
    padding-top: 15px;
    text-align: center;
}

footer h3 {
    color: #014A99;
    margin: 0px;
}

.footer_info {
    height: 200px;
    background-color: #014A99;
    /*background: repeating-linear-gradient(to bottom right, #0a7f9c44, #ffffff 25%);*/
    margin-bottom: 20px;
    margin-left: 8%;
    margin-right: 8%;
}

.footer_container {
    background-color: #014A99;
}

.logoFelp {
    width: 90px;
    height: 90px;
}

.felpDesc {
    background-color: #032d5a;
    height: 25px;
}

.face_pag {
    margin: 25px 0px 0px 40px;
    float: left;
    border-radius: 5px;
}

@media (max-width: 1000px) {
    .divHeader {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10%;
        margin-left: 0;
        margin-right: 0;
    }

    .menu {
        font-size: 1.5em;
        margin-top: 10px;
    }

    .menu_item {
        padding: 0px 5px 0px 5px;
        text-decoration: none;
    }

    .logo {
        /*20% menor*/
        width: 304px;
        height: 176px;
        align-items: center;
    }

    .face_pag {
        margin: 25px 0px 0px 0px;
        float: none;
    }

    .footer_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 300px;
    }

    .address {
        align-items: center;
        align-self: center;
        width: auto;
        margin: 25px 0px 0px 0px;
    }

}